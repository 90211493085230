.delete-modal > .pf-c-modal-box__footer {
	display: block !important;
}

.float-right {
	float: right;
}

.nudge-right-btn {
	position: relative;
	right: 10px;
}
